export const CATALOG_TYPE_PRODUCT = 1
export const CATALOG_TYPE_COMPONENT = 2
export const CATALOG_TYPE_CONTAINER = 3
export const CATALOG_TYPE_NON_STOCK = 4

export const catalogTypes = [
  {
    id: CATALOG_TYPE_PRODUCT,
    name: 'Stock / Product',
    path: 'home-main-catalog-list-stocks',
  },
  {
    id: CATALOG_TYPE_COMPONENT,
    name: 'Stock / Component',
    path: 'home-main-catalog-list-stocks',
  },
  {
    id: CATALOG_TYPE_CONTAINER,
    name: 'Stock / Container',
    path: 'home-main-catalog-list-stocks',
  },
  {
    id: CATALOG_TYPE_NON_STOCK,
    name: 'Service/Non-Stock',
    path: 'home-main-catalog-list-non-stocks',
  },
]

export const getCatalogType = type => catalogTypes.find(catalog => catalog.id === type)
