var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.trashTableColumns,"is-searchable":false,"table-config-options":_vm.getTableConfigOptions(),"back-to-list-path":"home-main-catalog-list-non-stocks"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"name-cell",attrs:{"title":_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—'}},[_vm._v(" "+_vm._s(_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—')+" ")])]}},{key:"cell(icon)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer defaultIconColor",staticStyle:{"margin-bottom":"5px"},attrs:{"icon":_vm.renderIcon(data.item),"size":"24"}})]}},{key:"cell(brand)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'brand') ? _vm.getValue(data, 'brand').name : '—')+" ")]}},{key:"cell(category)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'categories') ? _vm.getValue(data, 'categories').map(function (category) { return category.name; }).join(' , ') : '—')+" ")]}},{key:"cell(model)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'detail').model ? _vm.getValue(data, 'detail').model : '—')+" ")]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(action)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"2px 6px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"feather-icon border-dotted cursor-pointer defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }